import { DEFAULT_MILESTONE_RANGE } from 'constants/milestone/Milestones.constants';
import { DIVERSITY_FILTER_OPTIONS } from 'dashboards/TrialManagement/constants/diversity.constants';
import { TRIAL_CARD_LIST_GROUPING_OPTIONS } from 'dashboards/TrialManagement/constants/22_TrialCardList.constant';
import { GLOBAL_FILTER_VALUE } from 'store/reducers/multiDashboardState';
import CHAT_JOURNEY_STEPS from 'components/ChatBotSection/constants/chatJourneySteps';
import { TIMELINE_ITEMS } from '../../../../constants/timeline/timeline.constants';
import * as UI_CONSTANTS from '../../constants';
import { ENROLLMENT_DRIVER_CONFIGURATION_INITIAL_STATE } from '../../configuration';

const INITIAL_STATE = {
  preferencesConfigMap: {
    [UI_CONSTANTS.TRIAL_MANAGEMENT_CHAT_CONTAINER]: {
      CHAT_NEXT_JOURNEY_SESSION_TOKEN: null,
      CHAT_JOURNEY_STEP: CHAT_JOURNEY_STEPS[0],
      CHAT_JOURNEY_SHOW: false,
    },
    [UI_CONSTANTS.KEY_FACTS_SECTION_ID]: {
      VISIBLE_SIDEBAR: '',
    },
    [UI_CONSTANTS.TRIAL_CARD_VIEW_SECTION_ID]: {
      GROUP_BY_KEY: TRIAL_CARD_LIST_GROUPING_OPTIONS[0],
    },
    [UI_CONSTANTS.ENROLMENT_CURVE_SECTION_ID]: {
      IS_ACTIVATION_DRILLDOWN_VISIBLE: false,
      IS_ENROLLMENT_DRILLDOWN_VISIBLE: true,
      CURVE_VISIBILITY: 'EO',
      ENROLLMENT_CURVE_TABLE: 'FALSE',
      ENROLLMENT_DATA: true,
      SITE_ACTIVATION_DATA: false,
      DATA_TABLE: false,
      // For COMPASSINS-1778: "Removing original plan"
      // DATA_TABLE_SERIES_VISIBILITY: ['original', 'planned', 'actual'],
      DATA_TABLE_SERIES_VISIBILITY: [
        'planned',
        'actual',
        'forecast',
        'probability',
      ],
      IS_ENROLLMENT_FORECAST_VISIBLE: {
        isVisible: false,
        type: '',
      },
      SELECTED_PLANS: [{ name: 'Aggregate', isChecked: true }],
    },
    [UI_CONSTANTS.EDC_READINESS_SECTION_ID]: {
      ACTIVE_EDC_SUMMARY_CARD_ID: null,
    },
    [UI_CONSTANTS.GLOBAL_FILTER_SECTION_ID]: {
      GLOBAL_FILTER_VALUE,
    },
    [UI_CONSTANTS.TRIAL_MANAGEMENT_NAVIGATION_ID]: {
      navigationList: [],
    },
    [UI_CONSTANTS.ACTIVATION_AND_ENROLLMENT_SECTION_ID]: {
      IS_ACTIVATION_DRILLDOWN_VISIBLE: false,
      IS_ENROLLMENT_DRILLDOWN_VISIBLE: true,
    },
    [UI_CONSTANTS.ACTIVATION_DRIVERS_SECTION_ID]: {
      COUNTRY_ACTIVATION_VIEW: 'Count',
      SITE_SELECTION_VIEW: 'Count',
      SITE_ACTIVATION_VIEW: 'Count',
      IS_SITE_ACTIVATION_DRILLDOWN_VISIBLE: false,
      SITE_ACTIVATION_CARD_VIEW: {
        currentToggle: 'site',
        isActive: false,
      },
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS]: {
      ...ENROLLMENT_DRIVER_CONFIGURATION_INITIAL_STATE,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREENING]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREENING]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.CYCLE_TIMES_SECTION_ID]: {
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_PLAN: 'CURRENT_PLAN',
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_CURRENT_NUMBER_SCREEN_FAILURE]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_CURRENT_NUMBER_DROPOUT]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_CURRENT_NUMBER_COMPLETION]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_CURRENT_NUMBER_ENROLLMENT]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_RATE_SCREENING]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_RATE_SCREEN_FAILURE]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_RATE_DROPOUT]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_RATE_COMPLETION]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRIVERS_RATE_ENROLLMENT]: {
      VISUALIZATION_TYPE: 'COUNTRY',
      IS_DATA_TABLE_VISIBLE: false,
      SELECTED_COUNTRY: null,
    },
    [UI_CONSTANTS.ENROLLMENT_DRILLDOWN_FILTER]: {
      DATA_ORDER: 'ACTUAL',
    },
    [UI_CONSTANTS.TRIAL_DELIVERY_GOALS_SECTION_ID.GPT]: {
      GROUP: false,
      TIMELINE: 6,
      MILESTONE: [],
      GPT_GOAL: [],
      GPT_STATUS: [],
      CURRENT_TRIAL_ID: null,
    },
    [UI_CONSTANTS.TRIAL_DELIVERY_GOALS_SECTION_ID.MILESTONE]: {
      GROUP: false,
      TIMELINE: 6,
      MILESTONE: [],
      GPT_GOAL: [],
      RAG_STATUS: [],
      MILESTONE_STATUS: [],
      CURRENT_TRIAL_ID: null,
    },
    [UI_CONSTANTS.PROTOCOL_AMENDMENT_SECTION_ID]: {
      IS_TIMELINE_VIEW_VISIBLE: false,
      IS_TOP_REASONS_VISIBLE: false,
      IS_PROTOCOL_AMENDMENT_TABLE_VISIBLE: false,
    },
    [UI_CONSTANTS.COMPLEX_MILESTONES_SECTION_ID]: {
      SELECTED_SORT_ORDER: [
        TIMELINE_ITEMS.PLANNED,
        TIMELINE_ITEMS.ACTUAL_AND_FORECAST,
      ],
      SHOW_DIFF: true,
      SELECTED_GROUP: [],
      NEXT_MILESTONE_STATUS: 'Projected',
      IS_ENROLLMENT_FORECAST_VISIBLE: {
        isVisible: false,
        type: '',
      },
      MILESTONE_RANGE: {
        startMilestone: DEFAULT_MILESTONE_RANGE.BEGIN_MILESTONE.value,
        endMilestone: DEFAULT_MILESTONE_RANGE.END_MILESTONE.value,
      },
    },
    [UI_CONSTANTS.SITE_LEADERBOARD_SECTION_ID]: {
      CHOSEN_TIMELINE: 'ALL-TIME',
    },
    [UI_CONSTANTS.TRIAL_DIVERSITY_SECTION_ID]: {
      VIEW_TYPE: DIVERSITY_FILTER_OPTIONS.VIEW_TYPE[1].value,
      COUNTRY: DIVERSITY_FILTER_OPTIONS.COUNTRY_DROPDOWN[0].value,
    },
  },
};

export default INITIAL_STATE;
