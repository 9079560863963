import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useAnalytics from 'utility/hooks/useAnalytic';

const AnalyticsContext = createContext(null);

export const useAnalyticsContext = () => useContext(AnalyticsContext);

function AnalyticsProvider({ children }) {
  const { analytic: analyticObject } = useAnalytics();

  return (
    <AnalyticsContext.Provider value={analyticObject}>
      {children}
    </AnalyticsContext.Provider>
  );
}
AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnalyticsProvider;
