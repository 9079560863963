import { PAGE_VISIT_EVENT } from 'constants/analyticEvents';
import {
  MENU_ID,
  SUB_MENU_PATH,
} from 'section/01_NavigationBarSection/constants/routeDetails';

const DEBUG_MODE = process.env.REACT_APP_ANALYTIC_DEBUG_MODE || false;
class GoogleAnalytic {
  #trackingId = null;

  constructor(trackingId, userId) {
    if (trackingId) {
      const gaTagManagerScript = document.createElement('script');

      this.#trackingId = trackingId;

      gaTagManagerScript.setAttribute(
        'src',
        `https://www.googletagmanager.com/gtag/js?id=${this.#trackingId}`,
      );

      document.head.appendChild(gaTagManagerScript);

      window.gtag('js', new Date());
      window.gtag('config', this.#trackingId, {
        user_id: userId,
        debug_mode: DEBUG_MODE,
        custom_map: {
          dimension2: 'page_path',
          dimension3: 'page_name',
          dimension4: 'user_group',
          dimension5: 'department',
        },
      });
    }
  }

  #routeProcess = (path) => {
    let route = null;
    Object.values(MENU_ID).forEach((currentSection) => {
      SUB_MENU_PATH[currentSection].forEach((currentRoute) => {
        const routeRegex = new RegExp(currentRoute.path);
        if (path.match(routeRegex)) {
          route = currentRoute.pathname;
        }
      });
    });
    return route;
  };

  sendGAPageVisit = (path) => {
    const pageVisitParams = {
      page_path: window.location.href,
      page_name: this.#routeProcess(path) || path,
    };
    window.gtag('event', PAGE_VISIT_EVENT, pageVisitParams);
  };

  sendGAEvent = (eventName, eventParams = null) => {
    if (eventParams === null) {
      window.gtag('event', eventName);
      return;
    }
    window.gtag('event', eventName, eventParams);
  };

  configurePageView = (title, path, location) => {
    window.gtag('config', this.#trackingId, {
      page_title: title,
      page_path: path,
      page_location: location,
    });
  };

  setGAUserProperties = (userProperties) => {
    window.gtag('set', 'user_properties', userProperties);
  };
}

export default GoogleAnalytic;
