export const PAGE_VISIT_EVENT = 'page_visit';
export const PAGE_VIEW_EVENT = 'page_view';
export const CHAT_EVENT = {
  SEND_MESSAGE: 'chat_send_message',
  PROMPT_SELECT: 'chat_prompt_select',
  PROMPT_CATEGORY_SELECT: 'chat_prompt_category_select',
  PROMPT_TOGGLE: 'chat_prompt_toggle',
  MINIMIZE: 'chat_minimize',
  CLOSE: 'chat_close',
  EXPAND: 'chat_expand',
  COPY: 'chat_copy',
  GUIDELINES_CLICK: 'chat_guidelines_click',
};

export const CHAT_EVENT_ATTRIBUTES = {
  page_title: 'COMPASS - Chat',
  page_path: '/chat',
};
