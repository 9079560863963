const CHAT_JOURNEY_STEPS = [
  {
    stepNumber: 0,
    chatOpen: false,
    value: 'CHAT_START',
    title: 'Meet your new AI assistant',
    description: `Ask questions and get quick insights on your trials. 
    You will find this icon on the bottom right corner of the Trial Management module.`,
    backButtonText: 'Show me later',
    nextButtonText: 'Take Tour',
    dialogPosition: {
      bottom: 160,
      right: 50,
    },
  },
  {
    stepNumber: 1,
    chatOpen: true,
    value: 'CHAT_INPUT',
    title: 'Ask your questions',
    description:
      'Type your question into the box, then press enter or click send',
    backButtonText: 'Back',
    nextButtonText: 'Next',
    dialogPosition: {
      bottom: 200,
      right: 550,
    },
  },
  {
    stepNumber: 2,
    chatOpen: true,
    value: 'CHAT_PROMPT_LIBRARY',
    title: 'Consult the prompt library',
    description:
      'Where to start? Click "Prompts" to expand the window and see frequently asked questions',
    backButtonText: 'Back',
    nextButtonText: 'Next',
    dialogPosition: {
      bottom: 250,
      right: 550,
    },
  },
  {
    stepNumber: 3,
    chatOpen: true,
    value: 'CHAT_WINDOW_EXPAND',
    title: 'Expand the window',
    description: 'Expand and collapse the chat window to increase readability',
    backButtonText: 'Back',
    nextButtonText: 'All set!',
    dialogPosition: {
      bottom: 400,
      right: 550,
    },
  },
];

export default CHAT_JOURNEY_STEPS;
