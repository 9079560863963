import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useThunkSelector } from 'utility/redux';
import { get } from 'lodash';
import { APPLICATION_VERSION } from 'constants/appVersion.constant';
import Analytic from '../GoogleAnalytic';

function getTrackingID() {
  const currentEnv = process.env.REACT_APP_ENV;
  if (currentEnv === APPLICATION_VERSION.ENV.PROD) {
    return process.env.REACT_APP_MEASUREMENT_ID;
  }
  return process.env.REACT_APP_TEST_MEASUREMENT_ID || null;
}

const getUserEmail = async (oktaAuth) => {
  const user = await oktaAuth.getUser();
  const userId = user.email;
  return userId;
};

const setGoogleAnalyticsUserProperty = async (oktaAuth, moduleAccess) => {
  try {
    const unknownPlaceholder = 'null';
    const userId = await getUserEmail(oktaAuth);
    const userGroup = get(
      moduleAccess,
      'payload.userProfile.userGroup',
      unknownPlaceholder,
    );
    const department = get(
      moduleAccess,
      'payload.userProfile.department',
      unknownPlaceholder,
    );
    const newUserProperties = {
      user_group: userGroup,
      department,
      email: userId,
    };
    return newUserProperties;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return null;
};

function useAnalytics() {
  const [userProperties, setUserProperties] = useState(null);

  const [analytic, setAnalytic] = useState(null);
  const { oktaAuth, authState } = useOktaAuth();

  const moduleAccess = useThunkSelector('moduleAccess');
  const location = useLocation();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (moduleAccess.isSuccess) {
      (async () => {
        const trackingID = getTrackingID();

        const googleAnalyticsUserProperty =
          await setGoogleAnalyticsUserProperty(oktaAuth, moduleAccess);

        if (!analytic && trackingID) {
          const newAnalytic = new Analytic(
            trackingID,
            googleAnalyticsUserProperty.email,
          );
          setAnalytic(newAnalytic);

          newAnalytic.setGAUserProperties(googleAnalyticsUserProperty);
          setUserProperties(googleAnalyticsUserProperty);
        }
      })();
    }
  }, [oktaAuth, authState, moduleAccess, analytic]);

  useEffect(() => {
    if (userProperties && analytic) {
      const path = window.location.pathname;
      analytic.sendGAPageVisit(path);
    }
  }, [location, userProperties, analytic]);

  return {
    analytic,
  };
}

export default useAnalytics;
